.index-title {
  animation: fromTop 0.5s;
  font-size: 2.5rem;
}

.content {
  display: flex;
  align-items: center;
  padding-top: 6%;
  margin-left: 10%;
}
.index-text {
  font-size: 1.4rem;
  animation: fromTop 1s;
  text-align: left;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
}
@keyframes fromTop {
  0% {
    transform: scaleY(0);
    transform-origin: top;
  }
}

@media screen and (min-width: 320px) and (max-width: 712px) {
  .content {
    padding-top: 20%;
    margin-left: 5%;
    margin-right: 5%;
  }
}
@media screen and (min-width: 713px) and (max-width: 978px) {
  .content {
    margin-left: 5%;
  }
}
@media screen and (min-width: 979px) and (max-width: 1560px) {
  .content {
    padding-top: 10%;
    margin-left: 5%;
    margin-right: 5%;
  }
}
