.div-boton,
.div-boton-custom {
  display: block;
  padding: 3% 1%;
  animation: fromTop 1s;
}

.boton-servicio {
  background-color: #4c56a5;
  margin: auto;
  font-size: 55px;
  color: #fff;
  border-width: 0;
  border-color: rgb(120, 120, 120);
  border-radius: 50%;
  width: 100px;
  height: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
  transition: transform 0.2s;
}

.div-boton .boton-servicio {
  cursor: pointer;
}
