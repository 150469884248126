.main {
  background-color: #4c56a5;
  display: flex;
  padding: 2% 0;
  justify-content: space-evenly;
  align-items: center;
}

.main p,
.main span {
  color: #fff;
}

span.address {
  font-weight: 400;
  font-size: 1.3rem;
  margin-left: 10px;
}

.contact-buttons {
  display: flex;
}

.icons {
  background-color: #fff;
  font-size: 25px;
  color: #000;
  border-width: 0;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0 10px;
  transition: transform 0.2s;
}
.icons:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.photo {
  width: 180px;
  height: 50px;
}

.countries img {
  width: 50px;
  height: 50px;
  margin: 0 5px;
  filter: drop-shadow(0 0 0.75rem);
}
@media screen and (min-width: 320px) and (max-width: 1032px) {
  .contact-buttons {
    display: inline;
  }

  .icons {
    width: 40px;
    height: 40px;
    margin-bottom: 15px;
  }
  .main {
    padding: 2% 10%;
    justify-content: space-between;
  }

  .photo {
    display: none;
  }

  .location p,
  .location span {
    font-size: 15px;
  }
  .location {
    margin: 0;
  }

  .copyright {
    font-size: 0.9rem;
    margin-left: 12px;
    text-align: center;
  }
}
