.p-section {
  padding: 2% 0;
  font-size: 1.4rem;
  text-align: left;
}

.title-section {
  animation: fromTop 1s;
  padding: 0 25% 0 25%;
  display: flex;
  align-items: center;
  text-align: center;
}
.title-section::before,
.title-section::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #000;

  top: 50%;
  left: 0;
}

.title-section:not(:empty)::before {
  margin-right: 0.25em;
}

.title-section:not(:empty)::after {
  margin-left: 0.25em;
}

@media screen and (min-width: 320px) and (max-width: 712px) {
  .p-section {
    padding: 2% 0;
    font-size: 1.3rem;
    text-align: left;
  }

  .title-section {
    animation: fromTop 1s;
    padding: 0 12% 0 12%;
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 1.5rem;
  }
}
