.div-servicios-custom {
  width: 25%;
  padding: 4% 1%;
  text-align: justify;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
}

.div-servicios {
  width: 15%;
  padding: 1%;
  text-align: center;
  margin: 50px 30px 50px 0;
  transition: transform 0.2s;
}
.div-servicios:hover {
  transform: scale(1.1);
}

@media screen and (min-width: 320px) and (max-width: 960px) {
  .div-servicios-custom {
    display: contents;
  }
  .div-servicios-custom h4,   .div-servicios-custom div{
    text-align: center;
  }
  .div-servicios-custom p {
    margin: 10% 0 20% 0;
    text-align: left;
  }
  .div-servicios {
    display: contents;
    text-align: center;
  }
}
