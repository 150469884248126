.div-equipo p {
  font-size: 1.2rem;
  text-align: center;
}
.div-equipo h4 {
  margin-bottom: 0;
}

.images-equipo {
  border-width: 0;
  border-radius: 50%;
  width: 240px;
  height: 240px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.2s ease-in-out;
}

.images-equipo-hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7);
  cursor: pointer;
}
