.photo {
  width: 180px;
  height: 50px;
}

.NavbarItems {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3% 10%;
  width: 100%;
  height: 40px;
  position: fixed;
  background-color: #fff;
  z-index: 9999;
  position: sticky;
  top: 0;
}

.nav-wrapper {
  display: grid;
  grid-template-columns: repeat(9, auto);
  grid-gap: 20px;
  list-style: none;
  align-items: center;
  text-align: center;
  justify-content: end;
}

.nav-sections {
  color: #000;
  text-decoration: none;
  font-family: "RobotoCondensedRegular";
  font-size: 1.2rem;
}
.nav-sections:hover {
  color: #4c56a5;
}

.nav-sections-active {
  color: #4c56a5;
  text-decoration: none;
  font-family: "RobotoCondensedRegular";
  font-size: 1.2rem;
}

.contact-button {
  border-radius: 15px;
  padding: 6px 30px;
  color: #373535;
  font-size: 15px;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(120, 120, 120);
  background-color: #f1f2fb;
  font-family: "RobotoCondensedRegular";
  font-size: 1.2rem;
  background-color: #dbdce2;
}

.contact-button:hover {
  cursor: pointer;
}

.language-separator {
  margin-left: 6px;
  margin-right: 6px;
}

.language-label {
  cursor: pointer;
}

.menu-selector {
  display: none;
}
.select-languages {
  background-color: transparent;
  border-style: solid;
  border-width: 0;
  border-color: rgb(120, 120, 120);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}
.select-languages img {
  width: 50px;
  height: 50px;
}

@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translateY(20%);
  }
  to {
    opacity: 1;
  }
}

@media screen and (min-width: 320px) and (max-width: 550px) {
  .nav-wrapper {
    position: absolute;
    left: -110%;
    margin: 0;
    z-index: -1;
  }
  .NavbarItems {
    z-index: 99;
    height: 88px;
  }
  .nav-sections {
    display: flex;
    padding: 1rem 3%;
    color: #222;
  }
  .menu-icons {
    display: flex;
    margin-right: 15px;
  }
  .menu-selector select {
    display: flex;
  }
  .menu-selector {
    display: flex;
    align-items: center;
  }
  .NavbarItems ul {
    list-style: none;
    padding: 5% 0 0 0;
    width: 100%;
    margin-top: 55%;
  }

  .nav-wrapper-active {
    width: 100%;
    background-color: #fff;
    z-index: 9999;
    list-style: none;
    animation: fadeInBottom 0.8s;
    position: fixed;
    margin-top: initial;
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 20px;
  }
}

@media screen and (min-width: 551px) and (max-width: 860px) {
  .nav-wrapper {
    position: absolute;
    left: -110%;
    margin: 0;
    z-index: -1;
  }
  .NavbarItems {
    z-index: 99;
    height: 88px;
  }
  .nav-sections {
    display: flex;
    padding: 1rem 3%;
    color: #222;
  }

  .NavbarItems ul {
    list-style: none;
    padding: 5% 0 0 0;
    width: 100%;
    margin-top: 55%;
  }

  .menu-icons {
    display: flex;
    margin-right: 15px;
  }
  .menu-selector select {
    display: flex;
  }
  .menu-selector {
    display: flex;
    align-items: center;
  }
  .nav-wrapper-active {
    width: 100%;
    background-color: #fff;
    z-index: 9999;
    list-style: none;
    animation: fadeInBottom 0.8s;
    position: fixed;
    margin-top: initial;
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 20px;
  }
}
@media screen and (min-width: 861px) and (max-width: 1380px) {
  .nav-wrapper {
    position: absolute;
    left: -110%;
    margin: 0;
    z-index: -1;
  }
  .NavbarItems {
    z-index: 99;
    height: 88px;
  }
  .nav-sections {
    display: flex;
    padding: 1rem 3%;
    color: #222;
  }

  .NavbarItems ul {
    list-style: none;
    padding: 5% 0 0 0;
    width: 100%;
    margin-top: 55%;
  }

  .menu-icons {
    display: flex;
    margin-right: 15px;
  }
  .menu-selector select {
    display: flex;
  }
  .menu-selector {
    display: flex;
    align-items: center;
  }
  .nav-wrapper-active {
    width: 100%;
    background-color: #fff;
    z-index: 9999;
    list-style: none;
    animation: fadeInBottom 0.8s;
    position: fixed;
    margin-top: initial;
    display: grid;
    grid-template-columns: repeat(8, auto);
    grid-gap: 20px;
  }
  .menu-selector {
    display: flex;
    align-items: center;
    margin-left: 55px;
  }
}
@media screen and (min-width: 1381px) {
  .nav-wrapper-active {
    display: none;
  }
}
