@font-face {
  font-family: "RobotoCondensedRegular";
  src: local("RobotoCondensedRegular"),
    url("./fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf")
      format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "RobotoCondensedBold";
  src: local("RobotoCondensedBold"),
    url("./fonts/Roboto_Condensed/RobotoCondensed-Bold.ttf") format("truetype");
  font-weight: bold;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

html {
  font-family: "RobotoCondensedRegular";
}

body {
  margin: 0;
  height: 100%;
}

#root {
  min-height: 100%;
}

main {
  text-align: center;
  border-radius: 100px;
}

h1 {
  color: #042b5f;
  font-family: "RobotoCondensedBold";
  font-size: 1.6rem;
}

h2,
h3,
p,
pre {
  color: #042b5f;
  font-family: "RobotoCondensedRegular";
  font-size: 1.3rem;
  margin-top: 1rem;
}

h4 {
  color: #000;
  font-family: "RobotoCondensedBold";
  font-size: 1.3rem;
}

/*enso*/
.index-image {
  animation: girar 1.5s linear;
  margin-left: 25%;
}
/*enso*/

/*imagen del servicio en paginas*/
.image-service {
  animation-name: fromRight;
  animation-duration: 1s;
  margin-right: 20%;
}
/*imagen del servicio en paginas*/

/*primer div*/

.div-main-title {
  height: 30rem;

  border-radius: 0px 165px 165px 0px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 500px;
}

/*primer div*/

.caso-review {
  display: flex;
}

/*equipo*/

.div-pages-servicios,
.div-pages-equipo {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  flex-wrap: wrap;
}

.icons {
  background-color: #4c56a5;
  font-size: 25px;
  color: #fff;
  border-width: 0;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  display: inline-grid;
  margin: 0 10px;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.icons-hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7);
  cursor: pointer;
}

.slider-equipo {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
}
.div-slider-equipo {
  padding: 0 5% 0 5%;
}
.images {
  display: contents;
}

.image-equipo {
  width: 100%;
}

.div-images {
  display: flex;
  padding: 3% 3%;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.div-images p {
  display: flex;
}
.img-equipos {
  margin-bottom: 2%;
  width: 45%;
}
.img-p {
  display: flex;
}

/*equipo*/

.div-main {
  margin-left: 5%;
  margin-right: 5%;
}

@keyframes fromRight {
  0% {
    transform: scaleX(0);
    transform-origin: bottom right;
  }
}

@keyframes girar {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.slider {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 250px;
}

.div-slider {
  padding: 0 0 2% 0;
}

.image-client {
  width: 60%;
  display: flex;
}

.image-client-hey-now {
  width: 30%;
}

.image-client-agesic {
  width: 80%;
  height: 70px;
}

.main-img {
  width: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 0px 165px 165px 0px;
  background: rgba(0, 0, 0, 0)
    linear-gradient(to bottom, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0.6) 100%)
    repeat 0 0;
}

.p-section {
  padding: 2% 0;
  font-size: 1.4rem;
  text-align: left;
}

.title-section {
  padding: 0 25% 0 25%;
  display: flex;
  align-items: center;
  text-align: center;
}
.title-section::before,
.title-section::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #000;

  top: 50%;
  left: 0;
}

.title-section:not(:empty)::before {
  margin-right: 0.25em;
}

.title-section:not(:empty)::after {
  margin-left: 0.25em;
}

@media screen and (min-width: 320px) and (max-width: 712px) and (max-height: 1280px) {
  .div-main-title {
    object-fit: cover;
    border-radius: 0px;
  }

  .index-image {
    display: none;
  }
  .image-service {
    display: none;
  }

  .image-client {
    width: 75%;
    display: flex;
  }

  .image-client-narrow {
    width: 60%;
  }

  .image-client-hey-now {
    width: 30%;
  }

  .image-client-agesic {
    width: 70%;
    height: 60px;
  }

  .image-client-narrow-sm {
    width: 60%;
  }

  .slider-equipo {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
  }
  .div-slider-equipo {
    padding: 0 5% 0 5%;
  }

  .image-equipo {
    width: 100%;
  }
  .div-images {
    display: block;
    padding: 0;
  }

  .img-equipos {
    width: 100%;
    margin: 10% 0 10%;
  }
  .div-pages-servicios {
    display: block;
  }
}

@media screen and (min-width: 713px) and (max-width: 978px) and (max-height: 1280px) {
  .div-main-title {
    object-fit: cover;
    border-radius: 0px;
  }

  .index-image {
    margin-right: 15%;
  }
  .image-service {
    right: 5%;
    top: 20%;
  }

  .image-client {
    width: 75%;
    display: flex;
  }

  .div-pages-servicios {
    display: block;
  }
  .section {
    margin-top: 10%;
  }
  .image-equipo {
    width: 100%;
  }

  .div-images {
    display: block;
    padding: 0;
  }

  .img-equipos {
    width: 100%;
    margin: 10% 0 10%;
  }
}

@media screen and (min-width: 979px) and (max-width: 1560px) {
  .index-image {
    right: 5%;
    top: 22%;
  }
  .image-service {
    right: 5%;
    top: 35%;
  }
  .div-main {
    margin-left: 5%;
    margin-right: 5%;
  }

  .image-client {
    display: flex;
  }

  .section {
    margin-top: 10%;
  }
  .image-equipo {
    width: 100%;
  }

  .div-images {
    display: block;
  }

  .img-equipos {
    width: 100%;
    margin: 10% 0 10%;
  }
}
