.div-p {
  text-align: left;
  width: 100%;
  margin: 5% 5% 5%;
  padding: 4% 2%;
  background-color: #f1f2fb;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  transition: transform 0.2s;
}
.class-img {
  width: 70%;
  margin-left: 20%;
  height: 10%;
}
.div-p-img {
  display: flex;
  align-items: center;
  margin: 2% 0;
}
@media screen and (min-width: 320px) and (max-width: 700px) {
  .div-p {
    text-align: left;
    width: 100%;
    margin: 10% 0 10%;
    padding: 1% 2%;
    background-color: #f1f2fb;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    transition: transform 0.2s;
  }
  .div-p-img {
    display: contents;
  }
  .class-img {
    width: 100%;
    margin-left: 0;
    height: 10%;
  }
}

@media screen and (min-width: 701px) and (max-width: 1100px) {
  .div-p {
    text-align: left;
    width: 100%;
    margin: 5% 0 5%;
    padding: 1% 2%;
    background-color: #f1f2fb;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    transition: transform 0.2s;
  }
  .div-p-img {
    display: contents;
  }
  .class-img {
    width: 70%;
    margin-left: 15%;
    height: 10%;
  }
}
@media screen and (min-width: 1101px) and (max-width: 1443px) {
  .div-p {
    text-align: left;
    width: 100%;
    margin: 5% 0 5%;
    padding: 1% 2%;
    background-color: #f1f2fb;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    transition: transform 0.2s;
  }
  .div-p-img {
    display: contents;
  }
  .class-img {
    width: 50%;
    margin-left: 25%;
    height: 10%;
  }
}
